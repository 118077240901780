import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Product } from '../models/product.model';
import { IProductIdBanner } from './order.service';

export interface IProductResponse {
  id: string;
  name: string;
  c_tabDescription: string;
  c_price_list: number;
  c_variant_masters: IVariantMaster[];
  currency?: string;
  price: number;
  price_max?: number;
  brand: string;
  svcNumber?: number;
  image_groups: IImageGroup[];
  variants: IVariant[];
  variation_attributes: IVariationAttribute[];
  variation_values: {
    size?: string;
    color?: string;
    collection?: string;
  };
}

export interface IMultiProductResponse {
  count: number;
  data?: IProductResponse[];
  total: number;
}

export interface IVariantMaster {
  c_master_collection: string | null;
  c_master_id: string;
  c_product_ids: string[];
}

export interface IImageGroup {
  images: IImage[];
  variation_attributes?: IImageVariantAttribute[];
  view_type: string;
}

export interface IImage {
  alt: string;
  link: string;
  title: string;
}

export interface IImageVariantAttribute {
  id: string;
  values: IImageVariantAttributeValue[];
}

export interface IImageVariantAttributeValue {
  value: string;
}

export interface IVariant {
  product_id: string;
  price: number;
  variation_values: IVariationValues;
}

export interface IVariationValues {
  color?: string;
  size?: string;
  collection?: string;
}

export interface IVariationAttribute {
  id: string;
  name: string;
  values: IVariationAttributeValues[];
}

export interface IVariationAttributeValues {
  name: string;
  value: string;
}

export interface IProductImage {
  productId: string;
  name: string;
  color: string;
  size: string;
  imageUrl: string;
  currentOnlinePrice: number;
}

export interface IAlt {
  default: string;
}

export interface ITitle {
  default: string;
}

export interface IDataImage {
  _type: string;
  abs_url: string;
  alt: IAlt;
  path: string;
  title: ITitle;
}

export interface IDataImageGroup {
  _type: string;
  images: IDataImage[];
  view_type: string;
  variation_attributes?: IDataImageVariantAttribute[];
}

export interface IDataImageVariantAttribute {
  id: string;
  values: IDataImageVariantAttributeValue[];
}

export interface IDataImageVariantAttributeValue {
  value: string;
}

export interface Name {
  default: string;
  fr: string;
}

export interface OnlineFlag {
  default: boolean;
  'default@Aritzia_CA': boolean;
  'default@Aritzia_US': boolean;
  'default@Aritzia_INTL': boolean;
}

export interface Searchable {
  default: boolean;
  'default@Aritzia_CA': boolean;
  'default@Aritzia_US': boolean;
  'default@Aritzia_INTL': boolean;
}

export interface IDefault {
  _type: string;
  markup: string;
  source: string;
}

export interface IFr {
  _type: string;
  markup: string;
  source: string;
}

export interface IShortDescription {
  default: IDefault;
  fr: IFr;
}

export interface IType {
  _type: string;
  master: boolean;
}

export interface IDataVariant {
  _type: string;
  link: string;
  orderable: boolean;
  product_id: string;
  variation_values: IVariationValues;
}

export interface IAttributeDefinitionName {
  default: string;
  fr: string;
}

export interface IDataVariationValue {
  _type: string;
  link: string;
  name: Name;
  orderable: boolean;
  position: number;
  value: string;
}

export interface IDataVariationAttribute {
  _type: string;
  attribute_definition_id: string;
  attribute_definition_name: IAttributeDefinitionName;
  id: string;
  link: string;
  name: Name;
  shared: boolean;
  slicing: boolean;
  values: IDataVariationValue[];
  variation_attribute_type: string;
}

export interface IDataVariationGroup {
  _type: string;
  link: string;
  orderable: boolean;
  product_id: string;
  variation_values: {
    color: string;
  };
}

export interface CArticleFit {
  default: string[];
  fr: string[];
}

export interface CModelHeight {
  default: string;
}

export interface CModelSize {
  default: string;
}

export interface CPercentageDiscount {
  'default@Aritzia_CA': number;
  'default@Aritzia_US': number;
  'default@Aritzia_INTL': number;
}

export interface CReturnable {
  'default@Aritzia_CA': string;
  'default@Aritzia_US': string;
  'default@Aritzia_INTL': string;
}

export interface CCollectionLayout {
  default: string;
  fr: string;
}

export interface CDefaultColor {
  default: string;
  'default@Aritzia_CA': string;
  'default@Aritzia_US': string;
  'default@Aritzia_INTL': string;
}

export interface CFabric {
  default: string[];
  fr: string[];
}

export interface CFit {
  default: string;
  fr: string;
}

export interface CHideProductLabelPDP {
  'default@Aritzia_CA': boolean;
  'default@Aritzia_US': boolean;
  'default@Aritzia_INTL': boolean;
}

export interface CIsSale {
  'default@Aritzia_CA': boolean;
  'default@Aritzia_US': boolean;
  'default@Aritzia_INTL': boolean;
}

export interface CLastImageUpdate {
  default: Date;
}

export interface CLength {
  default: string[];
  fr: string[];
}

export interface CProductLabel {
  default: string;
  'default@Aritzia_CA': string;
  'default@Aritzia_US': string;
  'default@Aritzia_INTL': string;
}

export interface CRise {
  default: string[];
  fr: string[];
}

export interface CSeoProductDesc {
  default: string;
  fr: string;
}

export interface CStyle {
  default: string[];
  fr: string[];
}

export interface ICTabDescription {
  default: IDefault;
  fr: IFr;
}

export interface Fr3 {
  _type: string;
  markup: string;
  source: string;
}

export interface CTabDetails {
  default: IDefault;
  fr: IFr;
}

export interface CTabFeatures {
  default: IDefault;
  fr: IFr;
}

export interface CTabSizeFit {
  default: string[];
  fr: string[];
}


export interface IDataProductResponse {
  _v: string;
  _type: string;
  _resource_state: string;
  brand: string;
  creation_date: Date;
  ean: string;
  id: string;
  last_modified: Date;
  link: string;
  name: Name;
  online_flag: OnlineFlag;
  owning_catalog_id: string;
  searchable: Searchable;
  short_description: IShortDescription;
  type: IType;
  unit: string;
  unit_quantity: number;
  upc: string;
  c_articleCategory: string;
  c_articleFit: CArticleFit;
  c_articleGeneric: string;
  c_articleType: string;
  c_buyingCode: string;
  c_buyingCodeID: number;
  c_buyingCodeUS: number;
  c_collection: string;
  c_color: string;
  c_department: string;
  c_division: string;
  c_fit: CFit;
  c_isSale: CIsSale;
  c_merchandise: string;
  c_merchandiseCategory: string;
  c_merchandiseDepartment: string;
  c_merchandiseGender: string;
  c_merchandiseSource: string;
  c_modelHeight: CModelHeight;
  c_modelId: string;
  c_modelLengthAsSize: string;
  c_modelSize: CModelSize;
  c_percentageDiscount: CPercentageDiscount
  c_refinementColor: IAttributeDefinitionName;
  c_returnable: CReturnable;
  c_season: string;
  c_seasonYear: string;
  c_seoProductDesc: CSeoProductDesc;
  c_size: string;
  c_styleName: string;
  c_styleNumber: string;
  c_tabDescription: ICTabDescription;
  c_tabDetails: CTabDetails;
  c_tabFeatures: CTabFeatures;
  c_tabSizeFit: CTabSizeFit;
  image_groups?: string;
  variation_attributes: IDataVariationAttribute[];
  variation_values: {
    size?: string;
    color?: string;
    collection?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private _host = environment.apigee.host;
  private _sfcc = environment.apigee.sfcc;
  private _shop = environment.product.shop;
  private _data = environment.product.data;

  constructor(
      private httpClient: HttpClient,
      private _translate: TranslateService
  ) {} // skipcq

  public getProductImagesById$(productBanner: IProductIdBanner): Observable<Product> {
    return this.httpClient.get<IProductResponse>(
        `${this._host}/${this._sfcc}/${productBanner.banner}/${this._shop}/${productBanner.productId}?expand=images,variations,prices${this._locale}`
    ).pipe(
        map((product: IProductResponse) => {
          return new Product(
              product.id,
              product.name,
              product.variation_attributes
                  .find((attr) => attr.id === 'color')
                  .values.find(
                  (value) => value.value === product.variation_values.color
              )?.name,
              product.variation_attributes
                  .find((attr) => attr.id === 'size')
                  .values.find(
                  (value) => value.value === product.variation_values.size
              )?.name,
              product.image_groups.find(
                  (group) => group.view_type === 'medium'
              )?.images[0].link || '',
              product.price
          );
        }),
        catchError((e) => {
          return this.getProductDetailData(productBanner.productId);
        })
    );
  }

  private getProductDetailData(id: string): Observable<Product> {
    const lang = this._translate.currentLang === 'fr' ? 'fr' : 'default';
    const url = `${this._host}/${this._sfcc}/${this._data}/${id}?expand=all_images,variations`;
    return this.httpClient.get<IDataProductResponse>(url).pipe(
        map((product: IDataProductResponse) => {
          let color = null;
          let size = null;
          if (product.variation_attributes && product.variation_values) {
            let colorObj = product.variation_attributes
              .find((attr) => attr.id === 'color')
              .values.find(
                (value) => value.value === product.variation_values.color
              ).name;
            if(colorObj[lang] === undefined) {
              color = colorObj[lang];
            } else {
              color = colorObj.default;
            }
            let sizeObj = product.variation_attributes
              .find((attr) => attr.id === 'size')
              .values.find(
                (value) => value.value === product.variation_values.size
              ).name;
            if(sizeObj[lang] !== undefined) {
              size = sizeObj[lang];
            } else {
              size = sizeObj.default;
            }
          }
          return new Product(
              product.id,
              product.name[lang],
              color,
              size,
              product.image_groups = '',
              null
          );
        }),
        catchError(() => {
          console.warn(`No product found for ${id}.`);
          return of(
              new Product(
                  id,
                  null,
                  null,
                  null,
                  null,
                  null
              ));
        })
    );
  }

  private get _locale(): string {
    if (this._translate.currentLang === 'fr') {
      return '&locale=fr';
    } else {
      return '';
    }
  }
}