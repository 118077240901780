import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Item } from '../../models/item.model';
import { Order } from "../../models/order.model";
import { Product } from '../../models/product.model';
import { Store } from '../../models/store.model';
import { TransactionItem } from '../../models/transaction-item.model';
import { Transaction } from '../../models/transaction.model';
import { AvailabilityService } from '../../services/availability.service';
import { LoadingService } from '../../services/loading.service';
import { LocationService } from '../../services/location.service';
import { MsAuthService } from '../../services/ms-auth.service';
import { OrderService } from '../../services/order.service';
import { ProductService } from '../../services/product.service';
import { IDataPair } from '../../shared/models/data-pair.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'order-detail-item',
  templateUrl: './order-detail-item.component.html',
  styleUrls: ['./order-detail-item.component.scss'],
})
export class OrderDetailItemComponent implements OnInit, OnDestroy{
  @Input() item: Item | undefined;
  @Input() order: Order | undefined;
  @Input() transactionItem: TransactionItem | undefined;
  @Input() transaction: Transaction;
  @Input() index: number | undefined;
  @Input() noImage = false;
  @Input() original = true; 
  private _productData: Product;
  public storeData: IDataPair[] = [];
  public storeReassigned = false;
  public storeControl: FormControl<IDataPair> = new FormControl();
  private _subscriptions = new Subscription();
  private currentStoreId;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _loadingService: LoadingService,
    private _availabilityService: AvailabilityService,
    private _locationService: LocationService,
    private _orderService: OrderService,
    private _productService: ProductService,
    private _translateService: TranslateService,
    private _msAuthService: MsAuthService,
    private _dialog: MatDialog
    ) {}

  public ngOnInit(): void {
    if (this.order) {
      // Sets store reassignment data and confirmation dialog
      this._subscriptions.add(this._locationService.stores$.subscribe((val) => {
        let stores;
        if(this.order.currency === "CAD") {
          stores = val.filter((store) => parseInt(store.id) < 2000 || store.id === 'DC01' || store.id === 'DC03');
        } else {
          stores = val.filter((store) => parseInt(store.id) >= 2000 || store.id === 'DC01' || store.id === 'DC05');
        }
        this._subscriptions.add(this._availabilityService.availabilityForVariant$(this.item.productId, stores.map((s: Store) => s.id))
          .subscribe((availability) => {

            //Figures out what store to filter out
            const isCanada = this.order.currency === 'CAD';
            this.storeData = availability[0].map((a) => {
              // sets quantity to 0 for channel that are unavailable to sell
              let availableChannel = a.availableChannels.find((channel) => channel.salesChannelId === 'RETAIL');
              let quantity = 0;
              if((a.storeId === 'DC01' || a.storeId === 'DC03' || a.storeId === 'DC05') && this.order.isDeliveryOrder) {
                quantity = a.quantity;
              } else {
                if((this.order.isDeliveryOrder || this.order.isTransferOrder) && availableChannel && availableChannel.salesChannelOrderDelivery) {
                  quantity = a.quantity;
                }
                if(this.order.isPickupOrder && availableChannel && availableChannel.salesChannelOrderPickup) {
                  quantity = a.quantity;
                }
              }
              return {
                key: a.storeId,
                value: `${a.storeId}-${a.storeName} [${this._translateService.instant('orderDetailItem.quantityOnHand')}:${quantity}]`
              }
            });

            // Removes irrelevant warehouses
            if(isCanada) {
              this.storeData = this.storeData.filter((store) => 
                parseInt(store.key) < 2000 || store.key === 'DC01' || store.key === 'DC03'
              );
              if(this.order.isPickupOrder) {
                this.storeData = this.storeData.filter((store) => 
                  store.key !== 'DC01' && store.key !== 'DC03'
                );
              }
            } else {
              // US
              this.storeData = this.storeData.filter((store) => 
                parseInt(store.key) >= 2000 || store.key === 'DC01' || store.key === 'DC05'
              );
              if(this.order.isTransferOrder) {
                this.storeData = this.storeData.filter((store) => 
                  store.key !== 'DC01'
                );
              } else if(this.order.isPickupOrder) {
                this.storeData = this.storeData.filter((store) => 
                  store.key !== 'DC01' && store.key !== 'DC05'
                );
              }
            }

            // Sets the drop down data and default value
            this.storeControl.setValue(this.storeData.find((s) => s.key === this.item.fulfillingStore));
            this.storeData = this.storeData.filter((store) => !store.value.includes(`[${this._translateService.instant('orderDetailItem.quantityOnHand')}:0]`) || store.key === this.item.fulfillingStore);
            
            this.currentStoreId = this.storeControl.value.key;
            //Confirmation Dialog
            this._subscriptions.add(this.storeControl.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => {
              if(this.storeControl.value.key !== this.currentStoreId) {
                const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
                  data: {
                    message: this._translateService.instant('orderDetailItem.changeAssignedStore')
                  }
                });
                dialogRef.afterClosed().subscribe(result => {
                  if(result) {
                    this._loadingService.startLoading();
                    this._orderService.reassignStore$(this.order.orderId, this.item.lineItem, val.key).subscribe((val) => {
                      this.storeReassigned = true;
                      this._loadingService.stopLoading();
                    }, ()=> {
                      this._loadingService.stopLoading();
                    });
                  } else {
                    this.storeControl.setValue(this.storeData.find((s) => s.key === this.item.fulfillingStore));
                  }
                });
              }
            }));
          }));
      }));
    }
    if (this.item) {
      this._subscriptions.add(this._productService.getProductImagesById$({
        productId: this.item.productId,
        banner: this.order.currency === 'CAD' ? 'Aritzia_CA' : 'Aritzia_US'
      }).subscribe((product: Product) => {
        this._productData = product;
      }));
    } else if (this.transactionItem) {
      this._subscriptions.add(this._productService.getProductImagesById$({
        productId: this.transactionItem.productId,
        banner: this.transaction.currency === 'CAD' ? 'Aritzia_CA' : 'Aritzia_US'
      }).subscribe((product: Product) => {
        this._productData = product;
      }));
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public statusClicked(): void {
    this._router.navigate(['items', this.index], { relativeTo: this._route });
  }

  public get imgUrl(): string {
    if(this._productData){
      if(this._productData.img) {
        return this._productData.img;
      } else {
        return '';
      }
    } else {
      return null;
    }
  }

  public get serialId(): string {
    if(this.transaction) {
      return this.transactionItem.serialId;
    } else {
      return '';
    }
  }

  public get ecommId(): string {
    if(this.transaction) {
      return this.transactionItem.ecommId;
    } else {
      return '';
    }
  }

  public get layawayId(): string {
    if(this.transaction &&
      this.transactionItem &&
      this.transactionItem.referenceId &&
      this.transactionItem.referenceId.charAt(0).toLowerCase() === 'l') {
      return this.transactionItem.referenceId;
    } else {
      return '';
    }
  }

  public get specialOrderId(): string {
    if(this.transaction &&
      this.transactionItem &&
      this.transactionItem.referenceId &&
      this.transactionItem.referenceId.charAt(0).toLowerCase() === 's') {
      return this.transactionItem.referenceId;
    } else {
      return '';
    }
  }

  public get salesDeliveryId(): string {
    if(this.transaction &&
      this.transactionItem &&
      this.transactionItem.referenceId &&
      this.transactionItem.referenceId.charAt(0).toLowerCase() === 'h') {
      return this.transactionItem.referenceId;
    } else {
      return '';
    }
  }

  public get name(): string {
    if (this._productData && this._productData.name) {
      return this._productData.name;
    }
    if(this.order) {
      return this.item.name;
    } else if(this.transaction) {
      return this.transactionItem.name;
    } else {
      return '';
    }
  }


  public get productId(): string {
    if (this._productData && this._productData.productId) {
      return this._productData.productId;
    }
    if(this.order) {
      return this.item.productId;
    } else if(this.transaction) {
      return this.transactionItem.productId;
    } else {
      return '';
    }
  }
  
  public get size(): string {
    if (this._productData && this._productData.size && this._productData.size !== '') {
      return this._productData.size;
    }
    if(this.order) {
      return this.item.size;
    } else {
      return '';
    }
  }
  public get quantity(): number {
    if(this.order) {
      return this.item.quantity;
    } else if(this.transaction) {
      return this.transactionItem.quantity;
    } else {
      return null;
    }
  }

  public get color(): string {
    if (this._productData && this._productData.color && this._productData.color !== '') {
      return this._productData.color;
    }
    if(this.order) {
      return this.item.color;
    } else {
      return '';
    }
  }

  public get price(): number {
    if(this.order) {
      return this.item.price;
    } else if(this.transaction) {
      return this.transactionItem.salePrice;
    } else {
      return null;
    }
  }

  public get originalPrice(): number {
    if(this.order) {
      return (this.item?.priceList ? this.item.priceList : this.item?.price) || 0;
    } else if(this.transaction) {
      return (this.transactionItem.initialPrice ? this.transactionItem.initialPrice : this.transactionItem.salePrice) || 0;
    } else {
      return null;
    }
  }

  public get currency(): string {
    if(this.order) {
      return this.order.currency;
    } else if(this.transaction) {
      return this.transaction.currency;
    } else {
      return '';
    }
  }

  public get currentOnlinePrice(): number {
    return this._productData ? this._productData.price : null;
  }

  public get isManager(): boolean {
    return this._msAuthService.isManager();
  }
}
