export class Product {
  private _productId: string;
  private _name: string;
  private _color: string;
  private _size: string;
  private _img: string;
  private _price: number;

  constructor(productId: string, name?: string, color?: string, size?: string, img?: string, price?: number){
    this._productId = productId;
    this._name = name;
    this._color = color;
    this._size = size;
    this._img = img;
    this._price = price;
  }

  public get productId(): string {
    return this._productId;
  }

  public get name(): string {
    return this._name ? this._name: '';
  }

  public get color(): string {
    return this._color ? this._color: '';
  }

  public get size(): string {
    return this._size ? this._size: '';
  }

  public get img(): string {
    return this._img ? this._img: '';
  }

  public get price(): number | null {
    return this._price;
  }
}