import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { datadogRum } from '@datadog/browser-rum';


environment.environmentLoaded$.subscribe(() => {
  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

  datadogRum.init({
    applicationId: `${process.env.NG_APP_DD_APPLICATION_ID}`,
    clientToken: `${process.env.NG_APP_DD_CLIENT_TOKEN}`,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: `${process.env.NG_APP_DD_SITE}`,
    service: 'pht',
    env: environment.dataDogEnv,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    usePartitionedCrossSiteSessionCookie: true,
  });
});


